@font-face {
     font-family: RobotoRegular;
     src: local("RobotoRegular"), url(assets/fonts/Roboto-Regular.ttf) format("truetype")
}

@font-face {
     font-family: RobotoMedium;
     src: local("RobotoMedium"), url(assets/fonts/Roboto-Medium.ttf) format("truetype")
}

@font-face {
     font-family: InterRegular;
     src: local("InterRegular"), url(assets/fonts/Inter-Regular.ttf) format("truetype")
}

@font-face {
     font-family: InterMedium;
     src: local("InterMedium"), url(assets/fonts/Inter-Medium.ttf) format("truetype")
}

@font-face {
     font-family: WorkSansRegular;
     src: local("WorkSansRegular"), url(assets/fonts/WorkSans-Regular.ttf) format("truetype")
}

@font-face {
     font-family: DMSansRegular;
     src: local("DMSansRegular"), url(assets/fonts/DMSans-Regular.ttf) format("truetype")
}

html {

     font-family: RobotoRegular !important;
     font-stretch: ultra-condensed !important;
     color: red !important;
}

.MuiCard-root {
     box-shadow: 0 7px 20px 0 rgb(90 114 123 / 21%) !important
}

.MuiCardContent-root a {
     text-decoration: none !important
}

.disabled {
     pointer-events: none;
     opacity: .5
}

.loader {
     font-size: 10px;
     margin: 30px auto;
     text-indent: -9999em;
     width: 10em;
     height: 10em;
     border-radius: 50%;
     background: #102888;
     background: -moz-linear-gradient(left, #102888 10%, rgba(45, 68, 185, 0) 42%);
     background: -webkit-linear-gradient(left, #102888 10%, rgba(45, 68, 185, 0) 42%);
     background: -o-linear-gradient(left, #102888 10%, rgba(45, 68, 185, 0) 42%);
     background: -ms-linear-gradient(left, #102888 10%, rgba(45, 68, 185, 0) 42%);
     background: linear-gradient(to right, #102888 10%, rgba(45, 68, 185, 0) 42%);
     position: relative;
     -webkit-animation: load3 1.2s infinite linear;
     animation: load3 1.2s infinite linear;
     -webkit-transform: translateZ(0);
     -ms-transform: translateZ(0);
     transform: translateZ(0)
}

.loader:before {
     width: 50%;
     height: 50%;
     background: #102888;
     border-radius: 100% 0 0 0;
     position: absolute;
     top: 0;
     left: 0;
     content: ''
}

.loader:after {
     background: #fff;
     width: 75%;
     height: 75%;
     border-radius: 50%;
     content: '';
     margin: auto;
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0
}

@-webkit-keyframes load3 {
     0% {
          -webkit-transform: rotate(0);
          transform: rotate(0)
     }

     100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg)
     }
}

@keyframes load3 {
     0% {
          -webkit-transform: rotate(0);
          transform: rotate(0)
     }

     100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg)
     }
}

#plate {
     text-transform: uppercase !important;
     font-size: 1.2rem !important;
     font-weight: 700
}

:root {
     --theme: blue;
     --sat: env(safe-area-inset-top);
     --sar: env(safe-area-inset-right);
     --sab: env(safe-area-inset-bottom);
     --sal: env(safe-area-inset-left);
     font-family: RobotoRegular !important;
     font-stretch: ultra-condensed !important;
}

::-webkit-scrollbar {
     width: 0px !important;
     background: transparent !important;
     /* make scrollbar transparent */
}


/* video seek slider styles */

@tailwind base;
@tailwind components;
@tailwind utilities;

#seek {
     margin: auto;
     appearance: none;
     -webkit-appearance: none;
     overflow: hidden;
     height: 0.25rem;
     width: 100%;
     cursor: pointer;
     border-radius: 2rem;
     /* iOS */
     z-index: 20;
}

#seek::-webkit-slider-runnable-track {
     background: #ddd;
}


/*
   * 1. Set to 0 width and remove border for a slider without a thumb
   * 2. Shadow is negative the full width of the input and has a spread 
   *    of the width of the input.
   */

#seek::-webkit-slider-thumb {
     -webkit-appearance: none;
     width: 0.5rem;
     /* 1 */
     height: 0.5rem;
     background: crimson;
     box-shadow: -100vh 0 0 100vh crimson;
}

#seek::-moz-range-track {
     height: 0.25rem;
     background: #ddd;
}

#seek::-moz-range-thumb {
     background: crimson;
     height: 0.5rem;
     width: 0.5rem;
     border-radius: 0 !important;
     box-shadow: -100vh 0 0 100vh crimson;
     box-sizing: border-box;
     border: 0px;
}

#seek::-ms-fill-lower {
     background: crimson;
}

#seek::-ms-thumb {
     background: #fff;
     border: 2px solid #999;
     height: 0.5rem;
     width: 0.5rem;
     box-sizing: border-box;
}

#seek::-ms-ticks-after {
     display: none;
}

#seek::-ms-ticks-before {
     display: none;
}

#seek::-ms-track {
     background: #ddd;
     color: transparent;
     height: 0.25rem;
     border: none;
}

#seek::-ms-tooltip {
     display: none;
}


/* Change the white to any color ;) */

input:-webkit-autofill,
input:-webkit-autofill:focus {
     /* box-shadow: 0 0 0 1000px rgba(61, 61, 61, 0.9) inset !important; */
     -webkit-text-fill-color: #333 !important;
     transition: background-color 5000s ease-in-out 0s;
     animation-fill-mode: both;
}

#email {
     /* box-shadow: 0 0 0 1000px rgba(61, 61, 61, 0.9) inset !important; */
     -webkit-text-fill-color: #fff !important;
     transition: background-color 5000s ease-in-out 0s;
     animation-fill-mode: both;
}

#password {
     /* box-shadow: 0 0 0 1000px rgba(61, 61, 61, 0.9) inset !important; */
     -webkit-text-fill-color: #fff !important;
     transition: background-color 5000s ease-in-out 0s;
     animation-fill-mode: both;
}

.loaderSpin {
     font-size: 10px;
     width: 1em;
     height: 1em;
     border-radius: 50%;
     position: relative;
     text-indent: -9999em;
     animation: mulShdSpin 1.1s infinite ease;
     transform: translateZ(0);
}

@keyframes mulShdSpin {

     0%,
     100% {
          box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
     }

     12.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
     }

     25% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
     }

     37.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
     }

     50% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
     }

     62.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
     }

     75% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
     }

     87.5% {
          box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
     }
}

.welcome-frame {
     padding: 15px;
     border-radius: 20px;
     background-color: #ececec;
     box-shadow: 1px 2px 5px #888;
     margin-bottom: 40px;


}


.suggestion {
     padding: 15px;
     border-radius: 20px;
     background-color: #7990ec2c;
     box-shadow: 1px 2px 20px 2px #888;
     border: 1px solid #242424;
     color: #000
}

.menu-container {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     padding: 5px;
     margin: auto;
     margin-bottom: 20px;
}

.menu-item {
     flex-basis: calc(24% - 10px);
     min-width: calc(24% - 10px);

}

.menu-card {
     width: 100%;
     display: flex;
     flex-direction: column;
     box-shadow: 1px 10px 10px rgba(0,0, 0, 0.25) !important; /* add box shadow with depth of 10px */
     align-items: center;
     transition: transform .2s; /* remove quotes around property value */
     gap: 0px;
   }
   
   .menu-card:active {
     transform: scale(0.95); /* scale down when clicked */
   }
   
.menu-item:not(:first-child):not(:last-child) {
     margin: 0 0px;
}

.button {
     display: inline-block;
     width: 90px;
     height: 90px;
     background: radial-gradient(circle at center, #0261ee, #0763ee, #468ddf, #b4d2f8);
     border-radius: 50%;
     line-height: 90px;
     color: white;
     text-align: center;
     font-size: 16px;
     transition: all 0.5s;
     position: relative;
     box-shadow: 1px 1px 30px rgba(112, 112, 112, 0.2);
}

.button:hover {
     transform: translateY(0px);
}



.button:hover::after {
     transform: scale(1.3, 1.5);
}


.nav-iframe {
     width: 100%;
     height: calc(100vh - 70px);
     border: none;

}